<template>
  <div class="teacher-info">
    <div class="base-info">
      <div class="avatar">
        <el-image style="width: 100%;height: 100%;" :src="teacherInfo.photo_text==null||teacherInfo.photo_text ==''?img_:teacherInfo.photo_text">
        </el-image>
      </div>
      <div class="info">
        <h2 class="name">{{ teacherInfo.clerk_name }} ( {{ teacherInfo.clerk_status == 1 ? "在职" : "离职" }} )</h2>
        <div class="vitae">
          <div class="row">
            <div class="key">毕业院校</div>
            <div class="val">{{ teacherInfo.graduated_from }}</div>
          </div>
          <div class="row">
            <div class="key">所学专业</div>
            <div class="val">{{ teacherInfo.major }}</div>
          </div>
          <div class="row">
            <div class="key">最高学历</div>
            <div class="val">{{ teacherInfo.education }}</div>
          </div>
          <div class="row">
            <div class="key">入职时间</div>
            <div class="val">{{ teacherInfo.entry_time }}</div>
          </div>
          <div class="row">
            <div class="key">出生日期</div>
            <div class="val">{{ teacherInfo.birthday }}</div>
          </div>
          <div class="row">
            <div class="key">联系方式</div>
            <div class="val">{{ teacherInfo.mobile }}</div>
          </div>
          <div class="row">
            <div class="key">校区</div>
            <div class="val">{{ teacherInfo.school_name }}</div>
          </div>
        </div>
        <div class="tag">
          <el-tag
              style="margin-right: 15rem;background-color: #E8EDFF;color: #666;border: none"
              v-for="(item, index) in teacherInfo.tags" :key="index"
              effect="dark">
            {{ item }}
          </el-tag>
        </div>
      </div>
    </div>
    <card class="card" title="个人简介" :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}">
      <p v-if="!!teacherInfo.summary&&teacherInfo.summary!==''">
        {{ teacherInfo.summary }}
      </p>
      <t-result v-else></t-result>
    </card>
    <card class="card" title="当前教学班" :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}">
      <list-template
          :table-data="tableData2"
          :table-config="tableConfig2"
      ></list-template>
    </card>
    <card class="card" title="历史分班记录" :title-style="{padding:'5rem 40rem',background:'#E8EDFF',color:'#1C4EFD',borderRadius:'0 0 4rem 4rem'}">
      <template slot="header-right">
        <router-link :to="{path:'./history',query:{id}}" style="float: right;line-height: 31rem;font-size: 14rem;">查看更多<i class="el-icon-arrow-right"></i></router-link>
      </template>
      <list-template
          :table-data="tableData"
          :table-config="tableConfig"
      ></list-template>
    </card>
    <el-button type="primary" icon="el-icon-arrow-left" style="margin-top: 40rem;" @click="$router.back();"> 返回</el-button>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import img_ from "@/assets/img/photo_boy.png"

export default {
	_config:{"route":{"path":"details","meta":{"title":"查看","style":0}}},
  data() {
    return {
      img_: img_,
      // 表格配置
      tableConfig: [
        {
          type: "index",
          label: "序号",
          width: "80rem",
        },
        {
          prop: "begin_date",
          label: "开始时间"
        },
        {
          prop: "end_date",
          label: "结束时间"
        },
        {
          prop: "class_name",
          label: "班级"
        },
        {
          prop: "class_type",
          label: "班级类型"
        },
        {
          prop: "teacher_type",
          label: "教师类型"
        },
        {
          prop: "subject",
          label: "教学科目"
        },
        {
          prop: "grade_name",
          label: "年级"
        },
        {
          prop: "school_name",
          label: "校区"
        }
      ],
      teacherInfo: {},
      // 表格中的数据
      tableData: [],
      // 当前教学班
      tableData2: [],
      tableConfig2: [
        {
          type: "index",
          label: "序号",
          width: "80rem",
        },
        {
          prop: "class_name",
          label: "班级"
        },
        {
          prop: "grade_name",
          label: "年级"
        },
        {
          prop: "class_type",
          label: "班级类型"
        },
        {
          prop: "teacher_type",
          label: "教师类型"
        },
        {
          prop: "subject",
          label: "教学科目"
        }
      ],
      id: null
    }
  },
  components: {
    card: Card
  },
  methods: {
    getData() {
      this.$_axios.get("/teacher/teacher-details", {
        params: {
          id: this.id
        }
      }).then(res => {
        let {data} = res.data;
        this.teacherInfo = data;
      })
      this.$_axios.get("/teacher/class", {params: {id: this.id}}).then(res => {
        let {data} = res.data;
        this.tableData = data;
      })
      this.$_axios2.get("/api/common/public/current-class", { params: { teacher_id: this.id } }).then(res => {
        let {data: { data }} = res;
        this.tableData2 = data;
      })
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.teacher-info {
  padding: 0 40rem;

  .base-info {
    padding-top: 60rem;
    display: flex;

    .avatar {
      margin-right: 30rem;
      width: 165rem;
      height: 240rem;
      background-color: #f5f7fa;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      flex: 1;
      font-size: 14rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        margin-top: 20rem;
        //margin-bottom: 40rem;
      }

      .vitae {
        display: grid;
        grid-template-columns: repeat(3, 33.33333333%);
        grid-row-gap: 30rem;

        .row {
          line-height: 20rem;
          display: flex;

          .key {
            margin-right: 20rem;
          }
        }
      }
    }
  }

  .card {
    margin-top: 25rem;
    border-top: 1rem solid #E8EDFF;

    p {
      font-size: 12rem;
      line-height: 20rem;
      padding: 20rem 0;
    }

    .table {
      padding: 24rem 44rem;
    }

  }
}
</style>
